import React, { useEffect, useRef ,useState} from "react";
import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import gsap from 'gsap';
 import {CopyShader} from "three-effectcomposer"
// import { BloomEffect, EffectComposer, EffectPass, RenderPass, ShaderPass } from "postprocessing";
// import threeShaderFXAA from 'three-shader-fxaa'
import glsl from 'babel-plugin-glsl/macro';
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

var V=glsl`varying vec2 vUv;\r
#define PI 3.1415926535897932384626433832795

uniform float uScrollSpeed;\r
uniform vec2 uViewportSizes;\r
uniform float uHoverState;\r
uniform vec2 uHover;\r
uniform float uTime;

void main() {

    vec4 newPosition = modelViewMatrix * vec4(position, 1.0);

    float dist = distance(uv, uHover);

    newPosition.z += sin(newPosition.y / uViewportSizes.y * PI + PI / 2.0) * - (uScrollSpeed * 15.0);

    newPosition.z += (uHoverState * 6.0) * sin(dist * 10.0 + (uTime * 1.5));

    gl_Position = projectionMatrix * newPosition;

    vUv = uv;\r
}`,
X=glsl`uniform sampler2D uImage;

varying vec2 vUv;

void main(){\r
    gl_FragColor=texture2D(uImage,vUv);\r
}`;



const ThreeCube = () => {
    const cubeRef = useRef(null);
    
    let [ over , setOver] = useState({
        itemIndex: null,
        hover: false
    })
 

    const mouseOverFun = (index) => {
        over.itemIndex = index
        over.hover = !over.hover
    }
    
    useEffect(() => {
		
		let model = [];
        const CameraState = {
            initX:0,
            x: 0,
            z:9,
            zoom: 0.4, 
        

            left:0,
            minLimitX: null,
            maxLimitX: null,
            scrolled:false,
            deltaY: 0,
            in: false
        }
        
        const wrapperSize = {
            width:document.documentElement.clientHeight *2.3 ,
            height: document.documentElement.clientHeight - 90
			// width:1000,
            // height: 600
        }
        var scene = new THREE.Scene();
       
        let camera = new THREE.PerspectiveCamera(45, wrapperSize.width / wrapperSize.height, 0.1, 1000);
        
        camera.position.z = CameraState.z;
        camera.position.y = 3;
        camera.lookAt(CameraState.initX,0)
        var renderer = new THREE.WebGLRenderer({  alpha: false , antialias: true});
        
        
        renderer.setSize(wrapperSize.width, wrapperSize.height);
        camera.aspect = (wrapperSize.width/ wrapperSize.height);
        renderer.shadowMap.enabled = false;
        renderer.autoClear = false;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		renderer.setPixelRatio(1.4);
        // 


		const BLOOM_SCENE = 1;
  
		const bloomLayer = new THREE.Layers();
		bloomLayer.set(BLOOM_SCENE);
	  
		const params = {
		  exposure: 1,
		  bloomStrength: 0.6,
		  bloomThreshold: 0,
		  bloomRadius: 0.3,
		  scene: "Scene with Glow"
		};
	  
		const darkMaterial = new THREE.MeshBasicMaterial({ color: "black" });
		const materials = {};
	
	
	
		const renderPass = new RenderPass(scene, camera);
		const bloomPass = new UnrealBloomPass(
		  new THREE.Vector2(wrapperSize.width, wrapperSize.height),
		  1.5,
		  0.4,
		  0.85
		);
		bloomPass.threshold = params.bloomThreshold;
		bloomPass.strength = params.bloomStrength;
		bloomPass.radius = params.bloomRadius;
	  
		const bloomComposer = new EffectComposer(renderer);
		bloomComposer.renderToScreen = false;
		bloomComposer.addPass(renderPass);
		bloomComposer.addPass(bloomPass);
	  
		const shaderPass = new ShaderPass(
		  new THREE.ShaderMaterial({
			uniforms: {
			  baseTexture: { value: null },
			  bloomTexture: { value: bloomComposer.renderTarget2.texture }
			},
			vertexShader: `
			varying vec2 vUv;
	  
			void main() {
	  
				vUv = uv;
	  
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}`,
			fragmentShader: `
			uniform sampler2D baseTexture;
				  uniform sampler2D bloomTexture;
	  
				  varying vec2 vUv;
	  
				  void main() {
	  
					  gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
	  
				  }
			`,
			defines: {}
		  }),
		  "baseTexture"
		);
		shaderPass.needsSwap = true;
	  
		const finalComposer = new EffectComposer(renderer);
		finalComposer.addPass(renderPass);
		finalComposer.addPass(shaderPass);
		scene.traverse(disposeMaterial);

        function onWindowResize() {
            wrapperSize.width = document.documentElement.clientHeight *2
            wrapperSize.height =document.documentElement.clientHeight - 90
            camera.aspect = (wrapperSize.width/ wrapperSize.height);
			
            camera.updateProjectionMatrix();
            renderer.setSize(wrapperSize.width, wrapperSize.height);
			// renderer.setPixelRatio(wrapperSize.width/wrapperSize.height);
			// console.log(wrapperSize.width/wrapperSize.height)
            camera.updateProjectionMatrix();
        }
        window.addEventListener('resize', onWindowResize);

        
       

        var lightBlueMaterialGlass = new THREE.MeshPhysicalMaterial( {
            metalness: 0.2,   // between 0 and 1
            roughness: 0.6, // between 0 and 1
            emissive: 0x000000, // between 0 and 1
            color: 0x1D939B,
            reflectivity: 1, // between 0 and 1 and
            clearcoat: 1.0,
            clearcoatRoughness: 0.39,
            fog:true,
            opacity:0.8,
            refractionRatio: 0.95,
            transparent: true,
            side: THREE.DoubleSide,
			specular: true
            // envMap: envMap,
        } );
        var lightBlueMaterial = new THREE.MeshPhysicalMaterial( {
            metalness: 0.2,   // between 0 and 1
            roughness: 0.8, // between 0 and 1
            // emissive: 0x000000, // between 0 and 1
            color: 0x0D4347
            // envMap: envMap,
        } );
        
		let material = new THREE.LineDashedMaterial( { color: 0x515151, linewidth: 3, dashSize: 0.2,  gapSize: 0.2 } );
        const lensMaterial = new THREE.MeshPhysicalMaterial({
            metalness: 1.0,
            roughness: 0.2,
            color: 0xffffff,
            // envMap: cubeRenderTarget.texture,
            refractionRatio: 0.95,
            transparent: true,
            opacity: 0.5,
            transmission: 0.1,
            side: THREE.FrontSide,
            clearcoat: 1.0,
            clearcoatRoughness: 0.39,
        })
		function addModels(){
			addJoystick()
			addHands()
			addHuman()
			addWheelStatue()
		}
		
		function addJoystick(){
			const joystick = new GLTFLoader()
			joystick.load(`./geometry/joystick22.gltf` ,async function (glb) {
				await glb.scene.traverse( function( node ) {
					// if ( node.isMesh ) { node.castShadow = true; }
				} );
			
				const root = glb.scene;
				root.scale.set(2,2,2);
				root.position.x = -6
				root.children[0].children[0].material.color.set(0x0E0E0E) // body
				root.children[0].children[1].material= lightBlueMaterial // buttons
				root.children[0].children[2].material.color.set(0x0E0E0E) // leather
				root.children[0].children[3].material = lightBlueMaterial // lights
				root.children[0].children[4].material = lightBlueMaterial // middle button
				root.children[0].children[5].material = lightBlueMaterial // output
				root.children[0].children[6].material = lightBlueMaterial // rl buttons
				// root.children[0].children[7].material = lightBlueMaterial // top plastic
				model.push(root)
				scene.add(root)
			},function (xhr){
				// console.log((xhr.loaded/xhr.total)*100, "% progress", xhr);  
			},function (err){console.log("error:", err)})
		}
		function addHands(){
			const hands = new GLTFLoader()
			hands.load('./geometry/hands.gltf', function (glb) {
				glb.scene.traverse( function( node ) {
					// if ( node.isMesh ) { node.castShadow = true; }
				} );
			
				const root = glb.scene;
				root.scale.set(2,2,2);
				root.position.z = 3
				root.rotation.y = 0.05
				root.position.x = -2.5;
				root.children[0].material = lightBlueMaterialGlass
				root.children[1].material.color.set(0x0E0E0E)
				root.children[2].material.color.set(0x0E0E0E)
				model.push(root)
				scene.add(root)
	
				root.children[0].layers.enable(BLOOM_SCENE);
				
			},function (xhr){ 
			},function (err){console.log("error")})
		}
		function addWheelStatue (){
			const secStatue = new GLTFLoader()
			secStatue.load('./geometry/statue.glb', function (glb) {
				glb.scene.traverse( function( node ) {
					// if ( node.isMesh ) { node.castShadow = true; }
				} );
			
				const root = glb.scene;
				root.scale.set(3,3,3);
				root.position.z = -3
				root.rotation.y = 0.3
				root.position.x = -1.5
				model.push(root)
				// root.children[0].children[0].children[0].children[0].children[0].material.color.set(0xffffff)
				root.children[0].children[0].material.color.set(0x0E0E0E)
				root.children[0].children[1].material.color.set(0x0E0E0E)
				root.children[0].children[2].material = lightBlueMaterial
				scene.add(root)
			},function (xhr){
				// console.log((xhr.loaded/xhr.total)*100, "% progress", xhr);  
			},function (err){console.log("error:", err)})
			
			
		}
		function addHuman(){
			const statue = new GLTFLoader()
			statue.load(`./geometry/human.glb`, function (glb) {
				glb.scene.traverse( function( node ) {
					// if ( node.isMesh ) { node.castShadow = true; }
				} );
			
				const root = glb.scene;
				// root.scale.set(2.5,2.5,2.5);
				root.position.x = 4
				root.position.y = -0.9
				root.rotation.y = -0.3
				root.rotation.z = 0.01
				root.position.z = -0.5
				root.scale.set(3,3,3);
				root.children[0].material.color.set(0x0E0E0E)
				scene.add(root)
			},function (xhr){
				// console.log((xhr.loaded/xhr.total)*100, "% progress", xhr);  
			},function (err){console.log("error")})
			
		}

		function addVector(){
			// first
			let geometryFr = new THREE.BufferGeometry();
			let curve = new THREE.CatmullRomCurve3([
				new THREE.Vector3(-5.5, 0, 0),
				new THREE.Vector3(3, 0, 0)
			]);
			let points = curve.getPoints(100);
			geometryFr.setFromPoints(points);
			
			let line = new THREE.Line(geometryFr, material);
			line.computeLineDistances(); 

			// second
			let geometrySec = new THREE.BufferGeometry();
			let curveSec = new THREE.CatmullRomCurve3([
				new THREE.Vector3(-1.5, 0, -3),
				new THREE.Vector3(-1.5, 0, -0.8),
				new THREE.Vector3(3, 0, -0.2)
			]);
			let pointsSec = curveSec.getPoints(100);
			geometrySec.setFromPoints(pointsSec);
			let lineSec = new THREE.Line(geometrySec, material);
			lineSec.computeLineDistances(); 

			// third
			let geometryThr = new THREE.BufferGeometry();
			let curveThr = new THREE.CatmullRomCurve3([
				new THREE.Vector3(-2.5, 0, 3),
				new THREE.Vector3(-1.5, 0, 1),
				new THREE.Vector3(-0.8, 0, 0.5),
				new THREE.Vector3(3, 0, 0.2)
			]);
			let pointsThr = curveThr.getPoints(100);
			geometryThr.setFromPoints(pointsThr);
			let lineThr = new THREE.Line(geometryThr, material);
			lineThr.computeLineDistances();


			// forth
			let geometryFrth = new THREE.BufferGeometry();
			let curveFrth = new THREE.CatmullRomCurve3([
				new THREE.Vector3(4.7, 0, 0),
				new THREE.Vector3(7, 0, 0)
			]);
			let pointsFrth = curveFrth.getPoints(10);
			geometryFrth.setFromPoints(pointsFrth);
			let lineFrth= new THREE.Line(geometryFrth, material);
			lineFrth.computeLineDistances();

			let geometry2 = new THREE.BufferGeometry()
			const points2 = [
	
				new THREE.Vector3(7.4, 0, -0.3),//c верх
				new THREE.Vector3(7.1, 0, 0.3),//b Низ
				new THREE.Vector3(7.6, 0, 0)//a   пр
			]
	
			geometry2.setFromPoints(points2)
			geometry2.computeVertexNormals()
	
			const mesh = new THREE.Mesh(geometry2, material)
			scene.add(mesh)
			scene.add(line);
			scene.add(lineSec);
			scene.add(lineThr);
			scene.add(lineFrth);


		}

		function addPlanes(){

			const loaderPlane = new THREE.TextureLoader();
			const plane = 
				new THREE.Mesh(
					new THREE.PlaneGeometry(2.1,1.2, 100, 100), 
					new THREE.MeshBasicMaterial({
						alphaTest:0.1,
						transparent: true,
						map: loaderPlane.load('/geometry/play.png')
					})
				);
			plane.position.x = -6
			plane.position.y = 1.8
			plane.rotation.x= -0.2
			scene.add(plane);


		

			const planeSec = 
			new THREE.Mesh(
				new THREE.PlaneGeometry(1.9,1), 
				new THREE.MeshBasicMaterial({
					alphaTest:0.1,
					transparent: true,
					map: loaderPlane.load('/geometry/hold.png')
				})
			);
		
			planeSec.position.z = 3.2
			planeSec.position.y = 0.5
			planeSec.position.x = -0.5;
			planeSec.rotation.x= -0.2
			scene.add(planeSec);


			const planeThr = 
			new THREE.Mesh(
				new THREE.PlaneGeometry(2.9,1.5),
				new THREE.MeshBasicMaterial({
					alphaTest:0.1,
					transparent: true,
					map: loaderPlane.load('/geometry/win.png')
				})
			);
			planeThr.position.z = -2
			planeThr.position.y = 1.2
			planeThr.position.x = 0.7
			planeThr.rotation.x= -0.2
			scene.add(planeThr);
		
			const planeFth = 
			new THREE.Mesh(
				new THREE.PlaneGeometry(1.7,0.4),
				new THREE.MeshBasicMaterial({
					alphaTest:0.1,
					transparent: true,
					map: loaderPlane.load('/geometry/humanText.svg')
				})
			);
			planeFth.position.y = -1.5
			planeFth.position.x = 4
			planeFth.rotation.x= -0.2
			scene.add(planeFth);
		}

        function setLight(){
			// DIRECTIONAL LIGHT
			const aLight = new THREE.AmbientLight(0xffffff, 1);

			const dLight = new THREE.DirectionalLight(0xffffff, 2);
			// const aLight = new THREE.AmbientLight(0xffffff, 100);

			// const dLight = new THREE.DirectionalLight(0xffffff, 30);
			dLight.position.x = -5
			dLight.position.y = -2
			dLight.position.z = 2
			// dLight.castShadow = true
			// dLight.shadow.mapSize.width = 1024;
			// dLight.shadow.mapSize.height = 1024;


			const dLightSec = new THREE.DirectionalLight(0xffffff,3);
			// const dLightSec = new THREE.DirectionalLight(0xffffff,30);
			dLightSec.position.x += -10
			dLightSec.position.y = 2
			dLightSec.position.z = -2
			// dLightSec.castShadow = true
			// dLightSec.shadow.mapSize.width = 1024;
			// dLightSec.shadow.mapSize.height = 1024;

			const dLightTh = new THREE.DirectionalLight(0xffffff, 1);
			// const dLightTh = new THREE.DirectionalLight(0xffffff, 30);
			dLightTh.position.x = 0
			dLightTh.position.y = 5
			dLightTh.position.z = 2
			// dLightTh.castShadow = true
			// dLightTh.shadow.mapSize.width = 1024;
			// dLightTh.shadow.mapSize.height = 1024;

			const dLightFr = new THREE.DirectionalLight(0xffffff, 50);
			dLightFr.position.x = 0
			dLightFr.position.y = 15
			dLightFr.position.z = -2

			const dLightFi = new THREE.DirectionalLight(0xffffff, 50);
			dLightFi.position.x = -10
			dLightFi.position.y = -15
			dLightFi.position.z = -5

			scene.add(aLight)
			scene.add(dLight);
			scene.add(dLightSec);
			scene.add(dLightTh);
			// scene.add(dLightFr);
			// scene.add(dLightFi);
		}
        
		function cameraSet(obj1 ,  targetX){
			gsap.to(camera.position,{
				duration:0.1,
				x:targetX
			})
			camera.updateProjectionMatrix();

		}
			

			
		function setNewCamera(){
			if (window.innerWidth >= 1024) {
				if (document.querySelector(".canvas_wrapper")) {
					CameraState.x  = -(document.querySelector(".canvas_wrapper").getBoundingClientRect().left - wrapperSize.height/4 )/250
					if (CameraState.left > document.querySelector(".canvas_wrapper").getBoundingClientRect().left) {
						CameraState.left = document.querySelector(".canvas_wrapper").getBoundingClientRect().left
					}
					// CameraState.x = 0
					
					if (CameraState.x >= 3) {
						CameraState.x = 3
					}
					if(CameraState.x <= -9){
						CameraState.x = -9
					}
						
						
					cameraSet(camera, CameraState.x)
					// if (model[3]) {
					// 	dLight.target  = model[3]
					// 	scene.add(dLight.target);
					// }
				
					
				}
			}
		}

		function render() {
			scene.traverse(darkenNonBloomed);
			bloomComposer.render();
			scene.traverse(restoreMaterial);
			finalComposer.render();
		}
		setLight()
		addVector()
		addModels()
		addPlanes()
		

		
		
		function animate () {
		
			setNewCamera()
			if (window.innerWidth >= 1024) {
				render()
				// renderer.render(scene, camera);
				requestAnimationFrame(animate);
			}  
			
		};
		if (window.innerWidth >= 1024) {
			animate();
		}
		cubeRef.current.appendChild(renderer.domElement);

		function disposeMaterial(obj) {
			if (obj.material) {
			obj.material.dispose();
			}
		}
		function darkenNonBloomed(obj) {
		if (obj.isMesh && bloomLayer.test(obj.layers) === false) {
			materials[obj.uuid] = obj.material;
			obj.material = darkMaterial;
		}
		}
		function restoreMaterial(obj) {
			if (materials[obj.uuid]) {
				obj.material = materials[obj.uuid];
				delete materials[obj.uuid];
			}
		}
	}, []);

  function createBox() {
	const geometry = new THREE.BoxBufferGeometry (15,15,15);
	const material = new THREE.MeshBasicMaterial({
	  color: new THREE.Color().setHSL(
		255,
		255,
		255
	  )
	});
	const sphere = new THREE.Mesh(geometry, material);
	return sphere;
  }
  function createPlane () {
	const geometry = new THREE.PlaneGeometry (50,15);
	const material = new THREE.MeshBasicMaterial({
	  color: "pink"
	  
	});
	const plane = new THREE.Mesh(geometry, material);
	
	return plane;

  }


    return (
        <>

            <div
                className="canvas_wrapper"
                ref={cubeRef}
                style={{  height: "100vh",  }}
            >
                <div className="item hands" onMouseOver={()=> mouseOverFun(1)} onMouseLeave={()=> mouseOverFun(1)} >
                    <div className="item__pop-up right">
                        <div className="pop-up__content">
                        <h5 className="title">
                            Hodl
                        </h5>
                        <p className="paragraph">
                            Redistribution of tokens from paper hands to diamond hands
                        </p>
                        </div>
                    </div>
                </div>
                <div className="item joystick" onMouseOver={()=> mouseOverFun(0)} onMouseLeave={()=> mouseOverFun(0)}>
                    <div className="item__pop-up top">
                        <div className="pop-up__content">
                            <h5 className="title">
                            PLAY
                            </h5>
                            <p className="paragraph">
                            In prediction pools
                            <br />
                            for real crypto events
                            </p>
                        </div>
                    </div>
                </div>
                <div className="item secStatue" onMouseOver={()=> mouseOverFun(2)} onMouseLeave={()=> mouseOverFun(2)}>
                    <div className="item__pop-up right">
                        <div className="pop-up__content">
                            <h5 className="title">
                                WIN
                            </h5>
                            <p className="paragraph">
                                Top players get pay-outs and unique nft drops
                            </p>
                        </div>
                    </div>
                </div>
                <div className="item statue" >
                    <div className="item__pop-up bottom">
                        <div className="pop-up__content">
                            <h5 className="title">
                                $WSBT
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ThreeCube;



